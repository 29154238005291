<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!--间隔栏 -->
			<div class="grabble"></div>
			<el-table :data="list" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts" @row-click="unit"
				:tree-props="{children:'unionDtoList'}">
				<!-- 组织名称 -->
				<el-table-column prop="unionName" label="组织名称" align="center">
					<template #default="scoped">
						<el-select v-if="scoped.row.isShowSelect" v-model="scoped.row.unionName">
							<el-option v-for="item in selectData" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<span v-else>{{ scoped.row.unionName }}</span>
					</template>
				</el-table-column>
				<!-- 管理员列表 -->
				<el-table-column prop="unionAdmins" label="管理员列表" align="center">
					<template #default="scoped">
						<el-select v-if="scoped.row.isShowSelect" v-model="scoped.row.unionAdmins">
						</el-select>
						<span v-else>{{ scoped.row.unionAdmins }}</span>
					</template>
				</el-table-column>
				<!-- 设置 -->
				<el-table-column width="120" prop="address" label="操作" align="center">
					<template #default="scoped">
						<el-button link type="primary" size="large" @click="append(scoped.row.unionId)"><el-icon class="cancel">
								<Setting />
							</el-icon>设置</el-button>
					</template>
				</el-table-column>
			</el-table>

		</el-card>
	</div>
</template>
<!-- <template>
	<div class="tabulation">
		<el-table :data="list" style="width: 100%; 
			                  margin-bottom: 20px" row-key="unionId" border :tree-props="{children:'unionDtoList'}">
			<el-table-column prop="unionName" label="组织名称">
				<template #default="scoped">
					<el-select v-if="scoped.row.isShowSelect" v-model="scoped.row.unionName">
						<el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<span v-else>{{ scoped.row.unionName }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="unionAdmins" label="管理员列表">
				<template #default="scoped">
					<el-select v-if="scoped.row.isShowSelect" v-model="scoped.row.unionAdmins">
					</el-select>
					<span v-else>{{ scoped.row.unionAdmins }}</span>
				</template>
			</el-table-column>



			<el-table-column width="120" prop="address" label="操作">
				<template #default="scoped">
					<el-button link type="primary" size="small" @click="append(scoped.row)"><el-icon class="cancel">
							<Setting />
						</el-icon>设置</el-button>
				</template>
			</el-table-column>

		</el-table>
	</div>
</template> -->
<script>
	import {
		Setting
	} from "@element-plus/icons-vue";
	import {
		defineComponent,
		ref,
		onMounted
	} from 'vue'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		TYderiver
	} from '../../utils/api'

	export default defineComponent({
		name: 'ceshi',
		components: {
			Setting
		},
		setup() {
			// 路由跳转
			const router = useRouter()

			const append = (unionId)=> {
				sessionStorage.setItem('processIds', unionId)
				router.push({
					name: 'SZargur',
					params: {
						UserId: 1
					}
				})
				// router.push({
				// 	path: '/SZargur',
				// 	query: {
				// 		id: row.unionId
				// 	}
				// })
			}
			let list = ref([])
			let flag = ref(false)
			init()
			async function init() {
				let res = await TYderiver()
				sessionStorage.getItem('token')
				// console.log(sessionStorage.getItem('token'));
				// console.log(res.data.data.unionList, '111111111111');
				list.value = res?.data?.data?.unionList.map(item => {
					return {
						...item,
						isShowSelect: false,
						isBut: false
					}
				})
			}

			// 显示下拉框
			function showSelect(column, event) {
				if (flag.value) return
				column.isShowSelect = true
				column.isBut = true
				flag.value = true
			}
			// 修改完后确定按钮
			function handleConfirm(row) {
				// 处理确定逻辑后在执行下面
				row.isShowSelect = false
				row.isBut = false
				flag.value = false
			}
			// 取消修改按钮
			function handleClone(row) {
				// 恢复原来的数据后在执行下面
				row.isShowSelect = false
				row.isBut = false
				flag.value = false
			}
			// 处理数据
			function handleDropdown(list) {
				let data = []
				data = list?.split(',').map((item, index) => {
					return {
						label: item,
						value: index
					}
				})
				return data
			}

			return {
				list,
				showSelect,
				handleDropdown,
				handleConfirm,
				handleClone,
				append
			}
		}
	})
</script>
<style lang="scss" scoped>
	::v-deep .el-form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.setting {
		display: flex;
	}

	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.summation {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 10px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 22%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: flex-end;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	::v-deep .el-scrollbar__thumb {
		display: none !important;
	}
</style>
<style>
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>